import { useEffect, useState } from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    VStack,
} from '@chakra-ui/react'
import _ from 'lodash'

import { baseUrl } from '../env'
import GenericCard from './GenericCard'
import GenericCardInput from './GenericCardInput'

import { GenericTextModel } from '../types/models'
import { StateUser } from '../types/utility'

const fetchCards = async (modelName: string, handleCardData: StateUser<(GenericTextModel|null)[]>) => {
    const response = await fetch(`${baseUrl}/${modelName}`)
    const data = await response.json()
    handleCardData(data)
}

function GenericCardStack({ modelName }: { modelName: string }) {
    const [cardData, setCardData] = useState<(GenericTextModel|null)[]>([])
    const [collapsedCards, setCollapsedCards] = useState<string[]>([])
    const updateCards = () => { fetchCards(modelName, setCardData) }
    useEffect(updateCards, [modelName])
    const refreshCards = () => { setCardData([...cardData, null]) }
    const cleanCardData = cardData.filter(c => c !== null) as GenericTextModel[]

    const lsName = `collapsed${modelName.charAt(0).toUpperCase()}${modelName.slice(1)}`

    if (localStorage.getItem(lsName)) {
        const localValue = JSON.parse(localStorage.getItem(lsName)!)
        if (collapsedCards.join(', ') !== localValue.join(', ')) {
            setCollapsedCards(JSON.parse(localStorage.getItem(lsName)!))
        }
    } else {
        localStorage.setItem(lsName, JSON.stringify([]))
    }

    const [uncollapsed, collapsed] = _.partition(cleanCardData, c => !collapsedCards.includes(c.id))

    return !cardData.length ?
        <GenericCardInput modelName={modelName} updateCards={updateCards} /> :
        <VStack>
            <GenericCardInput modelName={modelName} updateCards={updateCards} />
            {uncollapsed
            // @ts-expect-error
            .filter(card => !card.completedAt)
            .map(card => <GenericCard key={card.id} cardData={card} modelName={modelName} fetchCards={updateCards} refreshCards={refreshCards}/>) }
            {!collapsed.length ? null :
            <Accordion allowToggle>
                {collapsed.map(card =>
                    <AccordionItem key={card.id}>
                        <AccordionButton>
                            <Box flex='1' textAlign='left'>
                                {card.name}
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <GenericCard cardData={card} modelName={modelName} fetchCards={updateCards} refreshCards={refreshCards}/>
                        </AccordionPanel>
                    </AccordionItem>
                    )}
            </Accordion>}
        </VStack>
}

export default GenericCardStack