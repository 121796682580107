export const confirmableCardColors = {
    confirmed: {
      dayColor: 'black',
      bg: 'green.500',
      descriptionColor: 'black'
    },
    confirmable: {
      dayColor: 'black',
      bg: 'yellow.500',
      descriptionColor: 'black'
    },
    failed: {
      dayColor: 'black',
      bg: 'red.500',
      descriptionColor: 'black'
    },
    pending: {
      dayColor: 'green.500',
      bg: 'white',
      descriptionColor: 'gray.500'
    },
    fallback: {
      dayColor: 'black',
      bg: 'gray',
      descriptionColor: 'white'
    }
  }