import { useEffect, useState } from 'react'

import { Button, Radio, RadioGroup, Select, Stack } from '@chakra-ui/react'

import { baseUrl } from '../env'
import dayjs from '../dayjs'

import type { BareTask, TaskOccurrence } from '../types/models'
type GenericEntity = { id: number, name: string }
type Linkables = {
    tasks: BareTask[],
    taskOccurrences: TaskOccurrence[],
    goals: GenericEntity[],
    tactics: GenericEntity[],
    leisureActivities: GenericEntity[]
}

export default function Link() {
    const [linkType, setLinkType] = useState('TaskTactic')
    const [linkables, setLinkables] = useState<Linkables>({ tasks: [], taskOccurrences: [], goals: [], tactics: [], leisureActivities: [] })
    const [isDisabled, setIsDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [didSucceed, setDidSucceed] = useState(false)
    const [didError, setDidError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        async function fetchLinkables() {
            const res = await fetch(`${baseUrl}/linkables`)
            const data = await res.json()
            setLinkables(data)
        }
        fetchLinkables()
    }, [])
    
    const entity1Type = linkType === 'TaskTactic' || linkType === 'TaskGoal' ? 'task' : 'taskOccurrence'
    const entity2Type = linkType === 'TaskTactic' ? 'tactic' : linkType === 'TaskGoal' ? 'goal' : 'leisureActivity'

    const handleCreatePress = async () => {
        setIsLoading(true)
        const response = await fetch(`${baseUrl}/link`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                entity1: {
                    type: entity1Type,
                    id: (document.getElementById('entity1') as HTMLSelectElement)?.value
                },
                entity2: {
                    type: entity2Type,
                    id: (document.getElementById('entity2') as HTMLSelectElement)?.value
                }
            })
        })
        setIsLoading(false)
        if (response.ok) {
            setDidSucceed(true)
        } else {
            setDidError(true)
            console.log(response)
            try {
                const responseText = await response.text()
                console.error(responseText)
                const body = JSON.parse(responseText)
                const message = body && body.message
                if (message) {
                    setErrorMessage(message)
                }
            } catch (e) {
                console.error(e)
            }
        }
        setTimeout(() => {
            setDidError(false)
            setDidSucceed(false)
        }, 5000)
    }

    return (
        <Stack>
        <RadioGroup onChange={setLinkType} value={linkType}>
          <Stack direction='row'>
            <Radio value='TaskTactic'>TaskTactic</Radio>
            <Radio value='TaskGoal'>TaskGoal</Radio>
            <Radio value='Reward'>Reward</Radio>
          </Stack>
        </RadioGroup>
        <Select id='entity1'>
            {entity1Type === 'task' ?
                linkables.tasks.map((task: BareTask) => (
                    <option key={task.id} value={task.id}>{task.name}</option>
                )) :
                linkables.taskOccurrences.map((taskOccurrence: TaskOccurrence) => (
                    <option key={taskOccurrence.id} value={taskOccurrence.id}>{taskOccurrence.task.name + (taskOccurrence.date ? '(' + dayjs(taskOccurrence.date).format('MM-DD') + ')' : '')}</option>
                ))
            }
        </Select>
        <Select id='entity2'>
            {linkables[(entity2Type === 'leisureActivity' ? 'leisureActivities' : entity2Type + 's') as keyof Linkables].map((entity: any) => (
                <option key={entity.id} value={entity.id}>{entity.name}</option>
            ))}
        </Select>
        <Button
            isDisabled={isDisabled}
            isLoading={isLoading}
            colorScheme={didSucceed ? 'green' : didError ? 'red' : 'blue'}
            onClick={handleCreatePress}
        >
            {didSucceed ? 'Created link!' : didError ? (errorMessage || 'Failed to create link') : 'Create link' }
        </Button>
        </Stack>
      )
}