import React, { ChangeEvent } from 'react'

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Heading,
    Input,
    VStack,
} from '@chakra-ui/react'

import { baseUrl } from '../env'

function GenericCardInput({ modelName, updateCards }: { modelName: string, updateCards: () => void }) {
    const [name, setName] = React.useState('')
    const [description, setDescription] = React.useState('')
    const [tags, setTags] = React.useState<string[]>([])
    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => setName(event.currentTarget.value)
    const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => setDescription(event.currentTarget.value)
    const handleTagChange = (event: ChangeEvent<HTMLInputElement>) => setTags(event.currentTarget.value.split(',').map(s => s.trim()))

    const displayModelName = modelName === 'leisureActivities' ? 'leisure activity' : modelName.slice(0, -1)

    const [isLoading, setIsLoading] = React.useState(false)
    const [didError, setDidError] = React.useState(false)
    const [didSucceed, setDidSucceed] = React.useState(false)

    const handleCreatePress = async () => {
        const body = { name } as Record<string, any>
        if (description.length) {
            body.description = description
        }
        if (tags.length) {
            body.tags = tags
        }
        const response = await fetch(`${baseUrl}/${modelName}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        setIsLoading(false)
        if (response.ok) {
            setDidSucceed(true)
            setName('')
            setDescription('')
            setTags([])
            updateCards()
        } else {
            setDidError(true)
        }
        setTimeout(() => {
            setDidError(false)
            setDidSucceed(false)
        }, 5000)
    }

    return (
        <VStack align='stretch'>
            <Card>
                <CardHeader style={{ paddingBottom: '0px' }}>
                    <Heading size="md">Create {displayModelName}</Heading>
                </CardHeader>
                <CardBody>
                    Name: <Input value={name} onChange={handleNameChange} size='md' />
                    Description: <Input value={description} onChange={handleDescriptionChange} size='md' />
                    Tags: <Input value={tags.join(', ')} onChange={handleTagChange} size='md' />
                </CardBody>
            </Card>
            <Button
                isDisabled={!name.length}
                isLoading={isLoading}
                colorScheme={didSucceed ? 'green' : didError ? 'red' : 'blue'}
                onClick={handleCreatePress}
            >
                {didSucceed  ? `Created ${displayModelName}!` :
                    didError ? `Failed to create ${displayModelName}` :
                               `Create ${displayModelName}` }
            </Button>
        </VStack>
    )
}

export default GenericCardInput