import React, { ChangeEvent } from 'react'

import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Switch,
    VStack,
} from '@chakra-ui/react'

import { baseUrl } from '../env'
import dayjs from '../dayjs'

const selectedDaysNone = {
    'Mon': false,
    'Tue': false,
    'Wed': false,
    'Thu': false,
    'Fri': false,
    'Sat': false,
    'Sun': false
}

function TaskInput({ updateOccurrences }: { updateOccurrences: () => void }) {
    const [isRecurring, setIsRecurring] = React.useState(false)
    const [useActiveTime, setUseActiveTime] = React.useState(false)

    const [taskName, setTaskName] = React.useState('')
    const [taskDescription, setTaskDescription] = React.useState('')
    const [tags, setTags] = React.useState<string[]>([])
    const [selectedDays, setSelectedDays] = React.useState<{ [day: string]: boolean }>(selectedDaysNone)
    const [period, setPeriod] = React.useState(NaN)
    const [date, setDate] = React.useState('')
    const [activeTime, setActiveTime] = React.useState('')
    const handleTaskNameChange = (event: ChangeEvent<HTMLInputElement>) => setTaskName(event.currentTarget.value)
    const handleTaskDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => setTaskDescription(event.currentTarget.value)
    const handleTagChange = (event: ChangeEvent<HTMLInputElement>) => setTags(event.currentTarget.value.split(',').map(s => s.trim()))
    const handlePeriodChange = (event: ChangeEvent<HTMLInputElement>) => setPeriod(Number(event.currentTarget.value))
    const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
        let time
        try {
            time = dayjs(event.currentTarget.value).format('YYYY-MM-DD')
        } catch (error) {
            console.error(error)
        }
        setDate(time || '')
    }
    const handleActiveTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
        let time
        try {
            time = dayjs(event.currentTarget.value).toISOString()
        } catch (error) {
            console.error(error)
        }
        setActiveTime(time || '')
    }

    const [isLoading, setIsLoading] = React.useState(false)
    const [didError, setDidError] = React.useState(false)
    const [didSucceed, setDidSucceed] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')

    const handleCreatePress = async () => {
        const schedule = Object.entries(selectedDays)
        .filter(([_, selected]) => selected)
        .map(([day, _]) => day)
        setIsLoading(true)
        const body = {
            name: taskName,
            isRecurring,
            schedule,
            tags
        } as Record<string, any>
        if (taskDescription.length) {
            body.description = taskDescription
        }
        if (period) {
            body.period = period
        }
        if (activeTime) {
            body.activeTime = activeTime
        }
        if (date) {
            body.date = date
        }
        const response = await fetch(`${baseUrl}/tasks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        setIsLoading(false)
        if (response.ok) {
            setDidSucceed(true)
            setTaskName('')
            setTaskDescription('')
            setTags([])
            setIsRecurring(false)
            setSelectedDays(selectedDaysNone)
            setPeriod(NaN)
            updateOccurrences()
        } else {
            setDidError(true)
            const body = await response.json()
            const message = body && body.message
            if (message) {
                setErrorMessage(message)
            }
        }
        setTimeout(() => {
            setDidError(false)
            setDidSucceed(false)
        }, 5000)
    }

    return (
        <Box maxWidth='100%' overflowX='auto'>
        <VStack align='stretch'>
            <Card>
                <CardHeader style={{ paddingBottom: '0px' }}>
                    <Heading size="md">Create task</Heading>
                </CardHeader>
                <CardBody>
                    Task name: <Input value={taskName} onChange={handleTaskNameChange} size='md' />
                    Task description: <Input value={taskDescription} onChange={handleTaskDescriptionChange} size='md' />
                    Tags: <Input value={tags.join(', ')} onChange={handleTagChange} size='md' />
                    {useActiveTime ?
                        <div>Active time: <Input placeholder='Select Date and Time' size='md' type='datetime-local' onChange={handleActiveTimeChange} /></div> :
                        <div>Date: <Input placeholder='Select Date' size='md' type='date' onChange={handleDateChange} /></div>
                    }
                <div /><br />
                <FormControl display='flex' alignItems='center'>
                    <VStack>
                    <HStack>
                        <FormLabel htmlFor='task-date' mb='0'>
                            Use active time
                        </FormLabel>
                        <Switch
                            id='active-time'
                            // Makes it more or less line up with switch below
                            marginLeft={1}
                            onChange={e => {
                                setUseActiveTime(e.target.checked)
                                setDate('')
                                setActiveTime('')
                            }}
                        />
                    </HStack>
                    <HStack>
                        <FormLabel htmlFor='is-recurring' mb='0'>
                            Is recurring task
                        </FormLabel>
                        <Switch
                            id='recurring-task'
                            onChange={e => setIsRecurring(e.target.checked)}
                        />
                    </HStack>
                    </VStack>
                </FormControl>
                {isRecurring &&
                    <div>
                        <HStack marginTop='10px' marginBottom='10px'>
                            {Object.keys(selectedDays).map(day => (
                                <Button
                                    key={day}
                                    colorScheme={selectedDays[day] ? 'green' : 'gray'}
                                    onClick={() => setSelectedDays({ ...selectedDays, [day]: !selectedDays[day] })}
                                >
                                    {day}
                                </Button>
                            ))}
                        </HStack>
                        <div>OR</div>
                        <div>Recurs every{' '}
                            <Input
                                value={period || ''}
                                onChange={handlePeriodChange}
                                size='sm'
                                width='50px'
                            />
                            {' '}days
                        </div>
                    </div>
                }
                </CardBody>
            </Card>
            <Button
                isDisabled={!taskName.length}
                isLoading={isLoading}
                colorScheme={didSucceed ? 'green' : didError ? 'red' : 'blue'}
                onClick={handleCreatePress}
            >
                {didSucceed ? 'Created task!' : didError ? (errorMessage || 'Failed to create task') : 'Create task' }
            </Button>
        </VStack>
        </Box>
    )
}

export default TaskInput