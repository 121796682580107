import React, { ChangeEvent, useEffect } from 'react'

import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Select,
    Switch,
    VStack,
} from '@chakra-ui/react'

import { baseUrl } from '../env'
import dayjs from '../dayjs'

import type { BareTask } from '../types/models'

import { TaskOccurrence } from '../types/models'
import { StateUser } from '../types/utility'

const fetchTasks = async (handleTasks: StateUser<BareTask[]>) => {
    const response = await fetch(`${baseUrl}/tasks`)
    const data = await response.json()
    handleTasks(data.filter((t: BareTask) => t.isRecurring))
}

function TaskSelect({ updateOccurrences }: { updateOccurrences: () => void }) {
    const [recurringTasks, setRecurringTasks] = React.useState<BareTask[]>([])
    const updateRecurringTasks = () => { fetchTasks(setRecurringTasks) }
    useEffect(updateRecurringTasks, [])

    const [recurringTask, setRecurringTask] = React.useState(null as BareTask | null)
    const [useActiveTime, setUseActiveTime] = React.useState(false)

    const [date, setDate] = React.useState('')
    const [activeTime, setActiveTime] = React.useState('')
    const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
        let time
        try {
            time = dayjs(event.currentTarget.value).format('YYYY-MM-DD')
        } catch (error) {
            console.error(error)
        }
        setDate(time || '')
    }
    const handleActiveTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
        let time
        try {
            time = dayjs(event.currentTarget.value).toISOString()
        } catch (error) {
            console.error(error)
        }
        setActiveTime(time || '')
    }

    const [isLoading, setIsLoading] = React.useState(false)
    const [didError, setDidError] = React.useState(false)
    const [didSucceed, setDidSucceed] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')

    const handleCreatePress = async () => {
        setIsLoading(true)
        const body = {
            taskId: recurringTask!.id
        } as Record<string, any>
        if (activeTime) {
            body.activeTime = activeTime
        }
        if (date) {
            body.date = date
        }
        const response = await fetch(`${baseUrl}/tasks/${recurringTask!.id}/occurrences`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        setIsLoading(false)
        if (response.ok) {
            setDidSucceed(true)
            updateOccurrences()
        } else {
            setDidError(true)
            const body = await response.json()
            const message = body && body.message
            if (message) {
                setErrorMessage(message)
            }
        }
        setTimeout(() => {
            setDidError(false)
            setDidSucceed(false)
        }, 5000)
    }

    return (
        <Box maxWidth='100%' overflowX='auto'>
        <VStack align='stretch'>
            <Card>
                <CardHeader style={{ paddingBottom: '0px' }}>
                    <Heading size="md">Select recurring task</Heading>
                </CardHeader>
                <CardBody>
                    Task: <Select placeholder='Select Task' size='md' onChange={e => setRecurringTask(JSON.parse(e.target.value))}>
                        {recurringTasks.map(task => <option key={task.id} value={JSON.stringify(task)}>{task.name}</option>)}
                        </Select>
                    {useActiveTime ?
                        <div>Active time: <Input placeholder='Select Date and Time' size='md' type='datetime-local' onChange={handleActiveTimeChange} /></div> :
                        <div>Date: <Input placeholder='Select Date' size='md' type='date' onChange={handleDateChange} /></div>
                    }
                <div /><br />
                <FormControl display='flex' alignItems='center'>
                    <VStack>
                    <HStack>
                        <FormLabel htmlFor='task-date' mb='0'>
                            Use active time
                        </FormLabel>
                        <Switch
                            id='active-time'
                            // Makes it more or less line up with switch below
                            marginLeft={1}
                            onChange={e => {
                                setUseActiveTime(e.target.checked)
                                setDate('')
                                setActiveTime('')
                            }}
                        />
                    </HStack>
                    </VStack>
                </FormControl>
                </CardBody>
            </Card>
            <Button
                isDisabled={!recurringTask}
                isLoading={isLoading}
                colorScheme={didSucceed ? 'green' : didError ? 'red' : 'blue'}
                onClick={handleCreatePress}
            >
                {didSucceed ? 'Created occurrence!' : didError ? (errorMessage || 'Failed to create occurrence') : 'Create occurrence' }
            </Button>
        </VStack>
        </Box>
    )
}

export default TaskSelect