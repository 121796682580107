import React, { useState } from 'react'
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
    VStack,
    HStack,
} from '@chakra-ui/react'
import { Filter, StateUser } from '../types/utility'

interface TaskOccurrenceCardStackFiltersType {
    name: string
    urgency: number | null
    urgencyOperator: string
    importance: number | null
    importanceOperator: string
    difficulty: number | null
    difficultyOperator: string
    avoidance: number | null
    avoidanceOperator: string
}

export default function TaskOccurrenceCardStackFilters({ filters, setFilters }: { filters: Filter[], setFilters: StateUser<Filter[]> }) {
    const [filtersI, setFiltersI] = useState<TaskOccurrenceCardStackFiltersType>({
        name: '',
        urgency: null,
        urgencyOperator: 'eq',
        importance: null,
        importanceOperator: 'eq',
        difficulty: null,
        difficultyOperator: 'eq',
        avoidance: null,
        avoidanceOperator: 'eq',
    })

    const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const { name, value } = event.target

        setFiltersI((prevFilters) => ({
            ...prevFilters,
            [name]: value !== '' ? (name === 'name' || name.includes('Operator') ? value : parseInt(value)) : null,
        }))
    }

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        const otherFilters = filters.filter(f => 
            !['name', 'urgency', 'importance', 'difficulty', 'avoidance'].includes(f.type))
        const newFilters = [...otherFilters]
        if (filtersI.name) {
            newFilters.push({ positive: true, type: 'name', value: filtersI.name })
        }
        ;(['urgency', 'importance', 'difficulty', 'avoidance'] as const).forEach(type => {
            const value = filtersI[type]
            const operator = filtersI[`${type}Operator`]
            console.log({ type, operator, value })
            if (value) {
                let allowedValues = [1, 2, 3, 4]
                if (operator === 'eq') {
                    allowedValues = [value]
                } else if (operator === 'gt') {
                    allowedValues = allowedValues.filter(v => v > value)
                } else if (operator === 'lt') {
                    allowedValues = allowedValues.filter(v => v < value)
                }
                newFilters.push({ type, value: allowedValues })
            }
        })
        setFilters(newFilters)
    }

    return (
        <Box as="form" onSubmit={handleSubmit}>
            <VStack spacing={4} align="stretch">
                <FormControl>
                    <FormLabel htmlFor="name">Name:</FormLabel>
                    <Input type="text" id="name" name="name" value={filtersI.name} onChange={handleFilterChange} />
                </FormControl>
                {(['urgency', 'importance', 'difficulty', 'avoidance'] as const).map((type) => (
                    <FormControl key={type}>
                        <FormLabel htmlFor={type}>{type.charAt(0).toUpperCase() + type.slice(1)}:</FormLabel>
                        <HStack>
                            <Select id={`${type}Operator`} name={`${type}Operator`} value={filtersI[`${type}Operator`]} onChange={handleFilterChange}>
                                <option value="eq">=</option>
                                <option value="gt">{'>'}</option>
                                <option value="lt">{'<'}</option>
                            </Select>
                            <Select id={type} name={type} value={filtersI[type] ?? ''} onChange={handleFilterChange}>
                                <option value="">Any</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </Select>
                        </HStack>
                    </FormControl>
                ))}
                <Button type="submit" colorScheme="blue">Apply Filters</Button>
            </VStack>
        </Box>
    )
}